import jobad from "./jobad";
import summarize from "./summarize";
import codeInterpret from "./interpret";
import intro from "./intro";
import helloworld from "./helloworld";
import example from "./example";
import datanarrator from "./datanarrator";
import casestudybuilder from "./casestudybuilder";
import researchgenie from "./researchgenie";
import narrativereportgenerator from "./narrativereportgenerator";
import activevoice from "./activevoice";
import claritywizard from "./claritywizard";
import instantsummary from "./instantsummary";
import rewritewizard from "./rewritewizard";
import objectivemaker from "./objectivemaker";
import outcomeplanner from "./outcomeplanner";
import smartindicatorcreator from "./smartindicatorcreator";
import activitymapper from "./activitymapper";
import verificationwizard from "./verificationwizard";
import theoryofchangegenerator from "./theoryofchangegenerator";
import coverlettercreator from "./coverlettercreator";
import quantitativequestionsgenerator from "./quantitativequestionsgenerator";
import focusgroupdiscussionquestionsgenerator from "./focusgroupdiscussionquestionsgenerator";
import kiioridiquestionsgenerator from "./kiioridiquestionsgenerator";
import assumptionmaker from "./assumptionmaker";
import freestyletool from "./freestyletool";

const TOOLS = [
  // codeInterpret,
  // intro,
  // jobad,
  // summarize,
  // helloworld,
  // example,
  datanarrator,
  casestudybuilder,
  researchgenie,
  narrativereportgenerator,
  activevoice,
  claritywizard,
  instantsummary,
  rewritewizard,
  objectivemaker,
  outcomeplanner,
  smartindicatorcreator,
  activitymapper,
  verificationwizard,
  assumptionmaker,
  theoryofchangegenerator,
  coverlettercreator,
  quantitativequestionsgenerator,
  focusgroupdiscussionquestionsgenerator,
  kiioridiquestionsgenerator,
  freestyletool,
];

export default TOOLS;
