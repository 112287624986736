import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const PasswordReset = ({ api, store }) => {
  const [passwordLinkSet, setPasswordLinkSet] = useState(false);
  const { token } = useParams();
  let query = useQuery();
  const email = query.get("email");
  console.log(email, token);
  return (
    <>
      <div className="flex min-h-full flex-col pt-32 justify-start sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md ">
          <img
            className="mx-auto scale-y-150 w-auto"
            src={process.env.PUBLIC_URL + "/logo1.png"}
            alt="Your Company"
          />
        </div>
        {email && token ? (
          <PasswordUpdateForm
            api={api}
            token={token}
            email={email}
            store={store}
          />
        ) : passwordLinkSet ? (
          <PasswordResetLinkSent />
        ) : (
          <PasswordResetForm
            api={api}
            setPasswordLinkSet={setPasswordLinkSet}
          />
        )}
        <Footer />
      </div>
    </>
  );
};

const PasswordResetForm = ({ api, setPasswordLinkSet }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
  } = useForm({
    revalidateMode: "onChange",
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    try {
      const result = await api.post("/auth/password/reset", {
        email: data.email,
      });
    } catch (e) {
      setError("code", {
        type: "server",
        message: e.response.data.error,
      });
    }
    setPasswordLinkSet(true);
  };
  const isDisabled = isSubmitting || !isValid;
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md flex-col items-center">
      <div className="text-center space-y-2 mb-3">
        <h3 className="font-semibold text-gray-900">Reset your password</h3>
        <p className="text-sm text-gray-600">
          Enter your email and we'll send you a link to reset your password.
        </p>
      </div>
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                {...register("email", {
                  required: "Email Address is required.",
                })}
              />
            </div>
          </div>
          {errors.email?.type === "required" && (
            <p
              className="mt-2 text-sm text-red-600"
              id="email-error"
              role="alert"
            >
              Email Address is required.
            </p>
          )}
          {errors.email?.type === "server" && (
            <p
              className="mt-2 text-sm text-red-600"
              id="email-error"
              role="alert"
            >
              {errors.email.message}
            </p>
          )}

          <div>
            <button
              type="submit"
              className={`flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2
              ${
                !isDisabled &&
                "bg-blue-400  hover:bg-blue-500 focus:ring-blue-500"
              }
              ${
                isDisabled &&
                "opacity-75 cursor-not-allowed bg-gray-300 text-gray-800  border-gray-400"
              } `}
              disabled={isDisabled}
            >
              {isSubmitting && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
const PasswordResetLinkSent = () => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.0}
        stroke="currentColor"
        className="text-green-500 mx-auto h-12 w-12"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <div className="text-center space-y-2 mb-3">
        <h3 className="font-semibold text-gray-900">
          We've sent you a password reset link.
        </h3>
        <p className="text-sm text-gray-600">
          You should receive an email any minute with a link to reset your
          password. If you don't receive an email, please check your spam
          folder.
        </p>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{" "}
          <a
            href="/login"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Sign in to your account &rarr;
          </a>
        </p>
      </div>
    </div>
  );
};
const PasswordUpdateForm = ({ api, email, token, store }) => {
  // Login to the app or redirect to the login page
  const defaultValues = {
    email: email,
    password: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
  } = useForm({
    revalidateMode: "onChange",
    mode: "onSubmit",
    defaultValues,
  });
  const isDisabled = isSubmitting || !isValid;
  const [isVerifying, setIsVerifying] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const result = await api.post("/auth/password/update", {
        email: data.email,
        password: data.password,
        token: token,
      });
      const { data: output, status } = result;
      if (status === 200) {
        store.loginWithDataTokenAndProfile(output);
        history.push("/");
      } else {
        setError("code", {
          type: "server",
          message: output.error,
        });
      }
    } catch (e) {
      setError("code", {
        type: "server",
        message: e.response.data.error,
      });
    }
    // TODO push to dashboard
  };
  const verifyToken = async () => {
    try {
      const result = await api.post("/auth/password/reset/verify", {
        email: email,
        token: token,
      });
      setIsVerifying(false);
      setIsVerified(true);
    } catch (e) {
      setIsVerifying(false);
      setIsVerified(false);
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <>
      {isVerifying ? (
        <Loading />
      ) : !isVerified ? (
        <TokenInvalid />
      ) : (
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex-col items-center">
          <div className="text-center space-y-2 mb-3">
            <h3 className="font-semibold text-gray-900">Reset your password</h3>
            <p className="text-sm text-gray-600">
              Enter your email and the new password you'd like to use to access
              your account.
            </p>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      {...register("email", {
                        required: "Email Address is required.",
                      })}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      {...register("password", {
                        required: "Password is required.",
                      })}
                    />
                  </div>
                </div>
                <p
                  className="mt-2 text-sm text-red-600"
                  id="email-error"
                  role="alert"
                >
                  {errors?.email?.message}
                </p>
                <div>
                  <button
                    type="submit"
                    className={`flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2
              ${
                !isDisabled &&
                "bg-blue-400  hover:bg-blue-500 focus:ring-blue-500"
              }
              ${
                isDisabled &&
                "opacity-75 cursor-not-allowed bg-gray-300 text-gray-800  border-gray-400"
              } `}
                    disabled={isDisabled}
                  >
                    {isSubmitting && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Update password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Loading = () => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md flex-col items-center">
      <div className="animate-plus" role="status">
        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
        <div className="h-5 mx-auto bg-gray-300 rounded-full dark:bg-gray-700 max-w-[50px]"></div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

const TokenInvalid = () => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="text-red-500 mx-auto h-12 w-12"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <div className="text-center space-y-2 mb-3">
        <h3 className="font-semibold text-gray-900">
          Invalid verification token.
        </h3>
        <p className="text-sm text-gray-600">
          Verification token is invalid or expired. Generate a new verification
          token by clicking the forgot password link below.
        </p>
        <p className="mt-2 text-center text-sm text-gray-600">
          <a
            href="/password/reset"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Forgot password &rarr;
          </a>
        </p>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="text-center mt-6">
      <div className="mt-8 md:order-1 md:mt-0 space-x-4">
        <span className="text-center text-sm leading-5 text-gray-800">
          Do you have an account?
        </span>
        <a
          href="/signup"
          className="cursor-pointer font-bold inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Sign Up &rarr;
        </a>
      </div>
    </footer>
  );
};

export default PasswordReset;
