import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Cover letter creator.",
  desc: "This tool generates an attention-grabbing cover letter tailored to a specific job description, using the work experience, qualifications, and skills listed in a user's CV as input.",
  category: "Personal",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/coverlettercreator",
  api: "/ai/coverlettercreator",

  output: {
    title: "Cover letter.",
    desc: "Generated cover letter.",
    Icon: false,
    color: "blue",
  },

  prompts: [
    {
      title: "Job Description and CV",
      desc: "Input job description and CV",
      // n: 1,
      prompts: [
        {
          title: "Job description",
          attr: "description",
          value: "",
          placeholder: "Job description",
          label: "",
          type: "textarea",
          maxLength: 1000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
        {
          title: "CV",
          attr: "cv",
          value: "",
          placeholder: "CV",
          label: "",
          type: "textarea",
          maxLength: 1000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        output: "",
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
