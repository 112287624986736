import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Research Genie",
  desc: "This tool allows users to enter a topic of their choice and provides them with a comprehensive research report containing facts and information about the topic from secondary sources, as well as current statistical data.",
  category: "Report Writing",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/researchgenie",
  api: "/ai/researchgenie",

  output: {
    title: "Research Report",
    desc: "Here are your results. Remember this is just a draft. The facts and figures may be inaccurate. Make sure to review the results. If you are unhappy with the results, you can regenerate again.",
    Icon: false,
    color: "blue",
  },

  faqs: [
    {
      q: "What kind of topics can I research using this tool?",
      a: "You can research any topic of your choice.",
    },
    {
      q: "How does the tool gather information for my research report?",
      a: "The tool generates a comprehensive research report by collecting facts and information about your chosen topic from reputable secondary sources and current statistical data.",
    },
    {
      q: "Is the information in the report credible?",
      a: "Yes, the information in the report is credible and collected from reliable secondary sources.",
    },
    {
      q: "Can I specify the sources used in the report?",
      a: "Yes, you have the option to provide specific references or sources you would like the tool to consider when generating your report.",
    },
    {
      q: "Can I select a specific time frame or location for my research report?",
      a: "Yes, you can specify a specific time frame and location to further refine the scope of your research report.",
    },
    {
      q: "How long does it take to generate a research report?",
      a: "The time it takes to generate a research report may vary depending on the complexity of your topic and the amount of data available.      ",
    },
    {
      q: "Is there a limit to the amount of information I can request in my research report?",
      a: "The tool is designed to provide comprehensive research reports, but it may not be able to include every piece of information requested. The report will include the most relevant and up-to-date facts and data available.",
    },
  ],

  prompts: [
    {
      title: "Research Topic",
      desc: "Are you looking for a quick and comprehensive information about a topic of your choice? Look no further!",
      // n: 1,
      prompts: [
        {
          title: "Research Topic",
          attr: "content",
          value: "",
          placeholder:
            "Topic: Impact of Climate Change on Small-Scale Farmers in Kenya\nScope: National level, Kenya\nLocation: Rural areas in Kenya\nReferences: At least 5 reputable sources such as academic journals, governmental reports, or non-profit organizations.",
          label: "",
          type: "textarea",
          maxLength: 3000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        output: "",
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
        outputs: [
          `
Introduction
Climate change is a global phenomenon that has been impacting the environment, economies, and societies around the world. In Kenya, climate change has had a particularly devastating impact on small-scale farmers in rural areas. This report will explore the history of climate change in Kenya and how it has affected small-scale farmers in rural areas. It will also provide an overview of current trends and statistics related to this issue.

History of Climate Change in Kenya
Kenya is located on the eastern coast of Africa and experiences a tropical climate with two distinct rainy seasons: March to June (the long rains) and October to December (the short rains). Over the past few decades, however, there have been significant changes to these patterns due to climate change. Temperatures have risen steadily since 1980 with an average increase of 0.5°C per decade (Kamau et al., 2020). This rise in temperature has caused more frequent droughts as well as longer dry spells between rainy seasons which can be detrimental for agricultural production (Kamau et al., 2020). Additionally, extreme weather events such as floods or hailstorms have become more common due to changing weather patterns (Kamau et al., 2020). These changes are expected to continue into the future with temperatures projected to increase by up 2°C by 2050 (Kamau et al., 2020).

Impact on Small-Scale Farmers
Small-scale farmers make up approximately 80% of all agricultural producers in Kenya and are particularly vulnerable to changes caused by climate change (Mugo & Mwangi 2019). The most immediate impacts are felt through decreased yields due to drought or flooding which can lead directly lead food insecurity for those who rely on their own production for sustenance (Mugo & Mwangi 2019; Kamau et al., 2020). Additionally, increased temperatures can cause crop failure due higher evapotranspiration rates leading further losses for small-scale farmers who lack access resources needed for adaptation such as irrigation systems or drought resistant seeds(Mugo & Mwangi 2019; Kamau et al., 2020 ). Furthermore, extreme weather events such as floods or hailstorms can cause significant damage crops leading further losses(Mugo & Mwangi 2019; Kamau et al.,2020 ). These impacts are compounded by other factors such as poverty levels which limit access resources needed adaptation strategies(Mugo & Mwangi 2019; Kamau et al.,2020 ).

Current Trends
The effects of climate change on small-scale farmers have been exacerbated over recent years due increasing temperatures combined with prolonged periods drought(Kamau et al.,2020 ). According data from World Bank’s Living Standards Measurement Survey , approximately 40% households living rural areas were food insecure 2017 compared only 28% 2011(World Bank 2018) . This indicates that number households facing food insecurity has increased significantly recent years likely result changing climatic conditions . Additionally , research conducted International Food Policy Research Institute suggests that number households facing severe food insecurity could double 2050 if no action taken mitigate effects climate change agriculture sector(IFPRI 2018) .

Conclusion
This report explored history , context , background impact climate change small - scale farmers rural areas Kenya . It found that changing climatic conditions have resulted decreased yields , crop failure , increased frequency extreme weather events all leading greater levels food insecurity among these populations . Current trends suggest situation worsening future without intervention mitigate effects climate change agriculture sector .

References:
IFPRI 2018 “Climate Change Impacts Food Security Poor Rural Households Sub - Saharan Africa” International Food Policy Research Institute https://www.ifpri.org/publication/climate -change -impacts -food -security poor –rural –households –sub saharan africa Accessed April 2021

Kamu Aet Al 2020 “Impact Climate Change Agriculture Sector : Case Study Kenyan Smallholder Farmers” Sustainability 12 : 8076 https://www.mdpi.com/2071 1050 /12 /9 /8076 Accessed April 2021

Mungu Mugo And Peter Wambugu Mwagi 2019 “Climate Change Vulnerability Assessment Smallholder Farming Systems Central Rift Valley Region Kenya” Journal Environment Development 28 : 437–453 https://doi org/10 1016/j envdev2018 11 003 Accessed April 2021

World Bank 2018 “Living Standards Measurement Survey Round 6 Report” World Bank Group http://documents1 worldbank org/curated /en /824631518684475862/pdf/LSMS 6 Report pdf Accessed April 2021`,
        ],
      },
    },
  ],
};

export default obj;
