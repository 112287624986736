import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Instant Summary",
  desc: "This tool enables users to create concise summaries of reports that accurately capture the main points while remaining true to the original content.",
  category: "Report Writing",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/instantsummary",
  api: "/ai/instantsummary",

  output: {
    title: "Summary",
    desc: "Summarize report.",
    Icon: false,
    color: "blue",
  },
  faqs: [
    {
      q: "What type of reports can this tool summarize?",
      a: "This tool can summarize a wide range of text including research reports, evaluation reports, and technical reports.",
    },
    {
      q: "How does the tool ensure the accuracy of the summary?",
      a: "The tool uses advanced algorithms to analyze the original report and generate a summary that accurately captures the main points while maintaining the original meaning.",
    },
    {
      q: "Can the summary be customized according to the user's needs?",
      a: "Yes, the tool provides options for adjusting the length and content of the summary to best meet the user's needs.",
    },
    {
      q: "How does the tool ensure the conciseness of the summary?",
      a: "The tool uses natural language processing techniques to remove redundant or irrelevant information, promoting conciseness while preserving the essential information.",
    },
    {
      q: "Is the tool able to summarize reports in multiple languages?",
      a: "Yes, the tool supports multiple languages and can generate summaries in the language of the original report.",
    },
    {
      q: "Is the tool able to summarize reports in a specific format?",
      a: "It can only summarize text that’s in plain text format right now but we’re working on other formats and will release this functionality in the near future. ",
    },
    {
      q: "How does the tool ensure the privacy and security of the original report?",
      a: "The tool employs strict security measures to protect the privacy and confidentiality of the original report and its contents. The summary generated by the tool is accessible only to the user who created it.",
    },
  ],

  prompts: [
    {
      title: "Summarize Report",
      desc: "This tool simplifies the process of summarizing text  by accurately capturing the main points and retaining the original content's meaning.",
      // n: 1,
      prompts: [
        {
          title: "Text to summarize",
          attr: "content",
          value: "",
          placeholder: `To generate a concise and accurate summary, paste your content here.. Specify the length (summarize in a 200 words or less) provide any specific guidelines or requirements, such as ”Ensure that the summary accurately captures the key findings and recommendations.

Introduction

Climate change is a global phenomenon that has been impacting the environment, ecosystems, and human activities for decades. It is particularly concerning in developing countries where agriculture is a major source of livelihood and food security. Climate change has caused extreme weather events such as droughts, floods, heat waves, and storms which have had devastating impacts on agricultural production in these countries. This research report will explore the impact of climate change on agriculture in developing countries by examining its effects on crop yields, soil fertility, water availability and access to markets. It will also discuss potential solutions to mitigate the impacts of climate change on agriculture in these countries.

Impact on Crop Yields

Climate change has had a significant impact on crop yields in developing countries due to increased temperatures and changes in precipitation patterns. Higher temperatures can reduce crop yields by increasing evaporation rates from soil surfaces which reduces available water for crops; this can lead to drought-like conditions even during periods of normal rainfall (1). Changes in precipitation patterns can also reduce crop yields due to flooding or lack of rain during critical growth stages (2). In addition, higher temperatures can increase pest infestations which further reduces crop yields (3). These impacts are particularly concerning for subsistence farmers who rely heavily on their own production for food security (4).

Impact on Soil Fertility

Climate change has also had an impact on soil fertility due to changes in temperature and precipitation patterns as well as increased levels of atmospheric carbon dioxide (CO2) (5). Higher CO2 levels have been linked with reduced nutrient availability from soils which decreases plant growth rates; this effect is known as “CO2 fertilization”(6). In addition, higher temperatures can cause soils to dry out more quickly leading to lower organic matter content which further reduces nutrient availability (7). Finally, changes in precipitation patterns can lead to erosion or leaching away of essential nutrients from soils reducing their fertility over time(8). These effects are especially concerning for smallholder farmers who rely heavily upon their own land resources for subsistence farming(9) .

 Impact On Water Availability And Access To Markets

 Climate change has also impacted water availability and access to markets due to extreme weather events such as floods or droughts that disrupt transportation networks or damage infrastructure necessary for market access(10) . In addition , higher temperatures increase evaporation rates leading to reduced surface water supplies while changes in rainfall patterns may cause flooding or drought-like conditions reducing available groundwater supplies(11) . These impacts are especially concerning given that many smallholder farmers lack access adequate irrigation systems.`,
          label: "",
          type: "textarea",
          maxLength: 3000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        outputs: [
          "Climate change is having a significant impact on agriculture in developing countries, affecting crop yields, soil fertility, water availability and access to markets. Higher temperatures can reduce crop yields by increasing evaporation rates from soil surfaces and increasing pest infestations. Changes in precipitation patterns can also reduce crop yields due to flooding or lack of rain during critical growth stages. Higher CO2 levels have been linked with reduced nutrient availability from soils which decreases plant growth rates while changes in precipitation patterns can lead to erosion or leaching away of essential nutrients from soils reducing their fertility over time. Extreme weather events such as floods or droughts that disrupt transportation networks or damage infrastructure necessary for market access are also impacting water availability and access to markets.",
        ],
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
