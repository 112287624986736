import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Verification Wizard",
  desc: "This tool helps to identify and develop a list of means of verification for a project using the programme objective, outcome, indicator and activities provided",
  category: "Logical Framework",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/verificationwizard",
  api: "/ai/verificationwizard",

  output: {
    title: "List of verification methods",
    desc: "List of verification methods",
    Icon: false,
    color: "blue",
  },

  prompts: [
    {
      title: "Programme Objectives, Outcomes, and Indicators",
      desc: "Programme Objectives, Outcomes, and Indicators",
      // n: 1,
      prompts: [
        {
          title: "Project Objectives & Outcomes",
          attr: "content",
          value: "",
          placeholder: "Objective, Outcome, and Indicator",
          label: "",
          type: "textarea",
          maxLength: 3000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        output: "",
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
