import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Clarity Wizard",
  desc: "This tool rewrites complex sentences into simpler versions without changing the original meaning, promoting clarity, correctness, and conciseness.",
  category: "Report Writing",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/claritywizard",
  api: "/ai/claritywizard",

  output: {
    title: "Simplified Sentences",
    desc: "Simplified sentences without changing the original meaning.",
    Icon: false,
    color: "blue",
  },
  faqs: [
    {
      q: "What is the purpose of the tool?",
      a: "The purpose of the tool is to simplify complex sentences into clear and concise versions without changing the original meaning.",
    },
    {
      q: "Who should use this tool?",
      a: "Anyone who wants to improve the clarity, correctness, and conciseness of their written text can use this tool.",
    },
    {
      q: "How does the tool work?",
      a: "The tool analyzes complex sentences and rewrites them into simpler versions while preserving the original meaning.",
    },
    {
      q: "Can the tool handle technical language or specialized vocabulary?",
      a: "Yes, the tool is designed to handle complex sentences, including technical language and specialized vocabulary.",
    },
    {
      q: "Is there a limit to the number of sentences I can simplify at once?",
      a: "No, there is no limit to the number of sentences you can simplify with the tool.",
    },
    {
      q: "Does the tool guarantee 100% accuracy?",
      a: "While the tool is designed to provide the most accurate simplification of complex sentences, there is always a chance for errors. It is recommended to review the simplifications for accuracy.",
    },
    {
      q: "How does the tool simplify sentences without changing the original meaning?",
      a: "The tool uses advanced algorithms and natural language processing techniques to simplify sentences while preserving the original meaning.",
    },
  ],

  prompts: [
    {
      title: "Simplify Sentences",
      desc: "Rewrite complex paragraphs.",
      // n: 1,
      prompts: [
        {
          title: "Transform complex sentences into clear and concise text.",
          attr: "content",
          value: "",
          placeholder:
            "Despite the fact that it has been repeatedly shown that the implementation of innovative pedagogical techniques has a positive impact on student learning outcomes, the utilization of these methods by educators has been limited due to a variety of reasons including a lack of proper training, a lack of available resources, and a general resistance to change by the educational establishment.",
          label: "",
          type: "textarea",
          maxLength: 3000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        outputs: [
          "Studies have repeatedly demonstrated that innovative teaching techniques improve student learning outcomes, yet educators often fail to use them due to inadequate training, limited resources and a reluctance to change.",
        ],
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
