const dev = {
  baseURL: "http://localhost:3080/api/",
  landingPageUrl: "http://localhost:3080",
  stripe: {
    free: "price_1Md8ZdIQcsu7jenT0PI0EXiL",
    entry: "price_1Md8bIIQcsu7jenT7Rtf7Qv4",
    pro: "price_1Md8bhIQcsu7jenTyf7e7F2P",
  },
};

const prod = {
  baseURL: "https://evaluationai-backend.onrender.com/api/",
  landingPageUrl: "https://evaluationai.com",
  stripe: {
    free: "price_1Md8ZdIQcsu7jenT0PI0EXiL",
    entry: "price_1Md8bIIQcsu7jenT7Rtf7Qv4",
    pro: "price_1Md8bhIQcsu7jenTyf7e7F2P",
  },
};

const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
