import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Active Voice",
  desc: "This tool helps to rewrite sentences from passive voice to active voice.",
  category: "Report Writing",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/activevoice",
  api: "/ai/activevoice",

  output: {
    title: "Active Voice Sentences",
    desc: "Sentences restructured from passive voice to active voice.",
    Icon: false,
    color: "blue",
  },
  faqs: [
    {
      q: "What is the purpose of the tool to rewrite sentences from passive voice to active voice?",
      a: "The purpose of the tool is to help users write more engaging, clear, and concise sentences by converting passive voice sentences into active voice sentences.",
    },
    {
      q: "Why is it important to use active voice in writing?",
      a: "Active voice makes sentences more direct and easy to understand, which can help to grab the reader's attention and convey the intended message effectively.",
    },
    {
      q: "What is the difference between passive and active voice in a sentence?",
      a: "In passive voice sentences, the subject is acted upon, while in active voice sentences, the subject performs the action.",
    },
    {
      q: "How does the tool work?",
      a: "The tool takes passive voice sentences as input and rewrites them into active voice sentences by identifying the subject, verb, and object and rearranging them accordingly.",
    },
    {
      q: "Can I use the tool to rewrite long paragraphs or just individual sentences?",
      a: "The tool can be used to rewrite both long paragraphs and individual sentences, depending on the user's needs.",
    },
    {
      q: "Does the tool only work for English sentences?",
      a: "Currently, the tool only works for English sentences, but support for other languages may be added in the future.",
    },
    {
      q: "Is the tool 100% accurate in converting passive voice sentences to active voice?",
      a: "The tool is designed to accurately convert passive voice sentences to active voice, but it may not always produce perfect results. The user may still need to review and edit the output to ensure that it meets their writing standards.",
    },
  ],
  prompts: [
    {
      title: "Passive To Active Voice",
      desc: "Rewrite sentences from passive voice to active voice.",
      // n: 1,
      prompts: [
        {
          title:
            "Transform passive voice sentences into clear and concise active voice sentences",
          attr: "content",
          value: "",
          placeholder:
            "The study was conducted by a team of researchers from the university. Participants were recruited through advertisements in local newspapers. Surveys were distributed to the participants, who were then asked to complete them. The results of the study were analyzed and reported in a comprehensive report.",
          label: "",
          type: "textarea",
          maxLength: 3000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        outputs: [
          "A team of researchers from the university conducted the study. They recruited participants through advertisements in local newspapers and distributed surveys for them to complete. After analyzing the results, they reported them in a comprehensive report.",
        ],
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
