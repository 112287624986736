import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Narrative Report Generator",
  desc: "This tool helps users to create cohesive, accurate, and engaging narrative reports based on a set of facts.",
  category: "Report Writing",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/narrativereportgenerator",
  api: "/ai/narrativereportgenerator",

  output: {
    title: "Narrative Report",
    desc: "Here are your results. Remember this is just a draft. The facts and figures may be inaccurate. Make sure to review the results. If you are unhappy with the results, you can regenerate again.",
    Icon: false,
    color: "blue",
  },

  faqs: [
    {
      q: "What is the purpose of this tool?",
      a: "The purpose of this tool is to help users create narrative reports that are cohesive, accurate, and engaging based on a set of facts.",
    },
    {
      q: "Who can use this tool?",
      a: "This tool is designed for monitoring, evaluation, research, and international development professionals who need to create narrative reports based on quantitative data.",
    },
    {
      q: "What kind of data can be input into this tool?",
      a: "This tool can handle a range of quantitative data and can transform it into a qualitative analysis for the narrative report.",
    },
    {
      q: "How does this tool generate a narrative report?",
      a: "The tool uses advanced algorithms to analyze the input data and create a narrative report based on the insights generated from the data.",
    },
    {
      q: "Is the output of this tool reliable?",
      a: "The tool is designed to generate accurate and reliable reports based on the input data. However, it's always important to double-check the output to ensure accuracy.",
    },
    {
      q: "Can I customize the output to meet my specific needs?",
      a: "The tool allows for a certain degree of customization to ensure the output meets the specific needs of the user.",
    },
    {
      q: "Why is it important to use this tool for narrative reports?",
      a: "Using this tool helps to ensure that the reports are cohesive, accurate, and engaging, providing a clearer picture of the data and making it easier for the reader to understand.",
    },
  ],

  prompts: [
    {
      title: "Research Topic",
      desc: "Research topic you wish to understand in narrative form.",
      // n: 1,
      prompts: [
        {
          title: "Input your data.",
          attr: "content",
          value: "",
          placeholder: `Jan 2020: Average temperature: 34°F, Average precipitation: 2.1 inches
Feb 2020: Average temperature: 38°F, Average precipitation: 1.7 inches
Mar 2020: Average temperature: 42°F, Average precipitation: 2.9 inches
Apr 2020: Average temperature: 50°F, Average precipitation: 3.5 inches
May 2020: Average temperature: 60°F, Average precipitation: 4.0 inches
Jun 2020: Average temperature: 70°F, Average precipitation: 3.2 inches
Jul 2020: Average temperature: 75°F, Average precipitation: 3.8 inches
Aug 2020: Average temperature: 74°F, Average precipitation: 4.1 inches
Sep 2020: Average temperature: 68°F, Average precipitation: 3.3 inches
Oct 2020: Average temperature: 56°F, Average precipitation: 2.7 inches
Nov 2020: Average temperature: 44°F, Average precipitation: 2.0 inches
Dec 2020: Average temperature: 36°F, Average precipitation: 1.5 inches
`,
          label: "",
          type: "textarea",
          maxLength: 3000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        outputs: [
          `NARRATIVE REPORT (Example)

        2020 was an interesting year for weather in the region. The year started off with a cold January, with an average temperature of 34°F and 2.1 inches of precipitation. As February arrived, temperatures began to warm up slightly, reaching 38°F on average and 1.7 inches of precipitation.

        March saw a further increase in temperature to 42°F and 2.9 inches of precipitation, while April saw temperatures rise even more dramatically to 50°F and 3.5 inches of precipitation - the highest amount seen so far that year! May continued this trend with 60°F on average and 4 inches of precipitation - the most rainfall seen all year!

        June brought some relief from the heat as temperatures dropped back down to 70°F on average but still had 3.2 inches of rainfall - more than any other month except May! July followed suit with 75°F on average but only 3.8 inches of rain, while August was slightly cooler at 74°F but still had 4.1 inches of rain - again more than any other month except May!

        September saw temperatures drop back down to 68°F on average and 3.3 inches of rain, while October experienced a further decrease in temperature to 56 ° F and 2 .7inchesofrainfall . November brought even colder weather , averaging 44 ° Fand2 . 0inchesofprecipitation , while December endedtheyearwith36 ° Fonaverageand1 . 5inchesofprecipitation .`,
        ],
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
