import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Freestyle Tool",
  desc: "Not getting what you want? Use this tool to instruct the ai on exactly what you want",
  category: "Freestyle/ Assistance",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/freestyletool",
  api: "/ai/freestyletool",

  output: {
    title: "Output",
    desc: "Generator output.",
    Icon: false,
    color: "blue",
  },

  prompts: [
    {
      title: "Input",
      desc: "Input for the generator",
      // n: 1,
      prompts: [
        {
          title: "Instruction",
          attr: "instruction",
          value: "",
          Iplaceholder: "Instruction",
          label: "",
          type: "textarea",
          maxLength: 1500,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
        {
          title: "Input Data",
          attr: "data",
          value: "",
          placeholder: "Data",
          label: "",
          type: "textarea",
          maxLength: 1500,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        output: "",
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
