import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import { getCountryCode } from "./getCountryCode";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { useState, forwardRef, createRef } from "react";
import { observer } from "mobx-react"; // Or "mobx-react".
import Navbar from "./Navbar";
import { useHistory } from "react-router-dom";

const VerifyOTP = ({ user, api, handleLogout }) => {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <Navbar handleLogout={handleLogout} />
      <div className="flex min-h-full flex-col justify-start sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-72 w-auto"
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="Evaluation AI"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-600 relative -top-20">
            Verify your phone number.
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md relative -top-20">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {user.phoneNumber ? (
              <VerificationCodeForm api={api} userId={user._id} />
            ) : (
              <PhoneNumberForm api={api} userId={user._id} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const VerificationCodeForm = ({ api, userId }) => {
  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setError,
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const isDisabled = isSubmitting || !isValid;
  const [isResending, setIsResending] = useState(false);
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const result = await api.post("/auth/otp/verify", {
        otp: data.code,
        userId: userId,
      });
      if (result.status === 200) {
        history.go(0);
      }
    } catch (e) {
      setError("code", {
        type: "server",
        message: e.response.data.error,
      });
    }
  };
  const resendVerificationCode = async () => {
    setIsResending(true);
    try {
      const result = await api.post("/auth/otp/resend", {
        userId: userId,
      });
      // if (result.status === 200) {
      //   window.location.reload(false);
      // }
    } catch (e) {
      setError("code", {
        type: "server",
        message: e.response.data.error,
      });
    }
    setIsResending(false);
  };
  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label
          htmlFor="code"
          className="block text-sm font-medium text-gray-700"
        >
          Verfication Code
        </label>
        <div className="mt-1">
          <input
            id="code"
            name="code"
            type="string"
            required
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            {...register("code", {
              required: true,
              maxLength: 6,
              minLength: 6,
              pattern: /^\d+$/,
            })}
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">
          Enter the code we sent you by SMS.
        </p>
        {errors.code?.type === "required" && (
          <p
            className="mt-2 text-sm text-red-600"
            id="email-error"
            role="alert"
          >
            Verification code is required.
          </p>
        )}
        {(errors.code?.type === "maxLength" ||
          errors.code?.type === "pattern" ||
          errors.code?.type === "minLength") && (
          <p
            className="mt-2 text-sm text-red-600"
            id="email-error"
            role="alert"
          >
            Invalid verification code.
          </p>
        )}
        {errors.code?.type === "server" && (
          <p
            className="mt-2 text-sm text-red-600"
            id="email-error"
            role="alert"
          >
            {errors.code.message}
          </p>
        )}
      </div>

      <div className="space-y-2">
        <button
          type="submit"
          className={`flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2
          ${!isDisabled && "bg-blue-400  hover:bg-blue-500 focus:ring-blue-500"}
          ${
            isDisabled &&
            "opacity-75 cursor-not-allowed bg-gray-300 text-gray-800  border-gray-400"
          } `}
          disabled={isDisabled}
        >
          {isSubmitting && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Submit Verification Code
        </button>
        <button
          type="button"
          className={`flex border-gray-300 w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm
          ${
            !isResending &&
            "bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          }
          ${
            isResending &&
            "opacity-75 cursor-not-allowed bg-gray-300 text-gray-800 border-gray-400"
          } `}
          onClick={resendVerificationCode}
          disabled={isResending}
        >
          {isResending && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Resend Verification Code
        </button>
      </div>
    </form>
  );
};
const PhoneNumberForm = observer(({ api, userId }) => {
  const {
    control,
    formState: { errors, isSubmitting, isValid: isClientValid },
    handleSubmit,
    setError,
  } = useForm({ mode: "onSubmit", reValidateMode: "onChange" });
  const isDisabled = isSubmitting || !isClientValid;
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const result = await api.post("/auth/phoneNumber", {
        phoneNumber: data.phoneNumber,
        userId: userId,
      });
      if (result.status === 200) {
        history.go(0);
      }
    } catch (e) {
      setError("phoneNumber", {
        type: "server",
        message: e.response.data.error,
      });
    }
  };
  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label
          htmlFor="phoneNumber"
          className="block text-sm font-medium text-gray-700"
        >
          Phone Number
        </label>
        <div className="mt-1">
          <PhoneInputWithCountry
            name="phoneNumber"
            control={control}
            rules={{ required: true, validate: isValidPhoneNumber }}
            defaultCountry={getCountryCode()}
            flags={flags}
            inputComponent={PhoneNumberInputComponent}
            countryCallingCodeEditable={false}
            withCountryCallingCode={true}
            international
          />
        </div>
      </div>
      {errors.phoneNumber?.type === "required" && (
        <p className="mt-2 text-sm text-red-600" id="email-error" role="alert">
          Phone number is required.
        </p>
      )}
      {errors.phoneNumber?.type === "validate" && (
        <p className="mt-2 text-sm text-red-600" id="email-error" role="alert">
          Invalid Phone Number.
        </p>
      )}
      {errors.phoneNumber?.type === "server" && (
        <p className="mt-2 text-sm text-red-600" id="email-error" role="alert">
          {errors.phoneNumber.message}
        </p>
      )}
      <div className="space-y-2">
        <button
          type="submit"
          className={`flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2
          ${!isDisabled && "bg-blue-400  hover:bg-blue-500 focus:ring-blue-500"}
          ${
            isDisabled &&
            "opacity-75 cursor-not-allowed bg-gray-300 text-gray-800  border-gray-400"
          } `}
          disabled={isDisabled}
        >
          {isSubmitting && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Save Phone Number
        </button>
      </div>
    </form>
  );
});

const PhoneNumberInputComponent = forwardRef(
  ({ onChange, value, ...rest }, ref) => {
    return (
      <input
        {...rest}
        id="phoneNumber"
        name="phoneNumber"
        type="tel"
        ref={ref}
        value={value}
        autoComplete="tel"
        onChange={onChange}
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
      />
    );
  }
);

export default VerifyOTP;
