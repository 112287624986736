import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Rewrite Wizard",
  desc: "This tool helps you rewrite or rephrase text without changing the original meaning by using different words, phrases, and sentences.",
  category: "Report Writing",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/rewritewizard",
  api: "/ai/rewritewizard",

  output: {
    title: "Rephrased",
    desc: "Rephrased text.",
    Icon: false,
    color: "blue",
  },
  faqs: [
    {
      q: "What is the purpose of this tool?",
      a: "The purpose of this tool is to help users rewrite or rephrase text without changing the original meaning, by using different words, phrases, and sentences.",
    },
    {
      q: "Is it possible to change the meaning of the original text?",
      a: "No, the tool ensures that the meaning of the original text remains unchanged.",
    },
    {
      q: "How does the tool choose new words and phrases?",
      a: "The tool uses advanced algorithms and natural language processing techniques to choose new words and phrases that preserve the original meaning.",
    },
    {
      q: "Can I use this tool for academic writing or professional work?",
      a: "Yes, this tool is suitable for academic writing, professional work, and other forms of writing where preserving the original meaning is important.",
    },
    {
      q: "Does the tool check for plagiarism?",
      a: "No, this tool does not check for plagiarism. It is the responsibility of the user to ensure that the rephrased text is not plagiarized.",
    },
    {
      q: "Is there a limit to the amount of text I can rewrite?",
      a: "Yes, the only limit to the amount of text you can rewrite using this tool is the number of credits you have.",
    },
    {
      q: "How long does it take to get the rephrased text?",
      a: "The amount of time it takes to get the rephrased text depends on the length and complexity of the original text. The tool processes the text as quickly as possible while ensuring accuracy and preserving the original meaning.",
    },
  ],

  prompts: [
    {
      title: "Rewrite Wizard",
      desc: "Rephrase and Rewrite assistant",
      // n: 1,
      prompts: [
        {
          title:
            "To get the best result from the tool, simply copy and paste the text you want to rewrite into the text box. The tool will then generate a new version of the text that retains the original meaning but uses different words, phrases, and sentences.",
          attr: "content",
          value: "",
          placeholder:
            "The report showed that there was a decrease in poverty levels in the region due to the implementation of new economic policies.",
          label: "",
          type: "textarea",
          maxLength: 3000,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        outputs: [
          "New economic policies led to a reduction in poverty levels in the region, according to the report.",
        ],
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
