import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "KII/ IDI questions Generator.",
  desc: "This tool helps create a set of open-ended questions to be used in a focus group discussion, tailored to the user's desired topic and number of questions.",
  category: "Develop Tools",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/kiioridiquestionsgenerator",
  api: "/ai/kiioridiquestionsgenerator",

  output: {
    title: "Questions",
    desc: "Series of questions.",
    Icon: false,
    color: "blue",
  },

  prompts: [
    {
      title: "Topic",
      desc: "Topic of the focus group discussion.",
      // n: 1,
      prompts: [
        {
          title: "Number of Questions",
          attr: "numberOfQuestions",
          value: "",
          placeholder: "10",
          label: "",
          //   type: "number",
          maxLength: 1500,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
        {
          title: "Topic",
          attr: "topic",
          value: "",
          placeholder: "Topic",
          label: "",
          type: "textarea",
          maxLength: 1500,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        output: "",
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
